import {bindActionCreators, Store} from 'redux'
import {setFormFactor} from '../../commons/actions/environment'
import {addToCalendarClicked} from '../actions/calendar'
import {
  checkout,
  clearCheckout,
  editStep,
  getDiscount,
  handleNextStep,
  nextFormClicked,
  setExpandedTicketIndex,
  setTicketDetails,
  setUseBuyerDetails,
  setValidPaymentAdded,
  submitCheckoutStep,
} from '../actions/checkout'
import {publishComponentSettings, updateStyleParams} from '../actions/component'
import {applyCoupon, resetCouponCode, setCouponCode} from '../actions/coupon'
import {getMembers, shareEvent} from '../actions/event'
import {clearInvoice} from '../actions/invoice'
import {detailsPageLoaded, formPageLoaded, thankYouPageLoaded, ticketedThankYouPageLoaded} from '../actions/loaded'
import {ensureLoginForMembersOnly, openMembersModal, promptLogin} from '../actions/members'
import {
  closeNativeModal,
  openCantCompletePaymentModal,
  openCheckoutUnavailable,
  openTicketsDetailsModal,
  openTicketsDownloadModal,
  openTimeExpiredModal,
} from '../actions/modals'
import {
  navigate,
  navigateToChangeRsvp,
  navigateToDetails,
  navigateToForm,
  navigateToMainPage,
  navigateToOrder,
  navigateToTicketsPicker,
  navigateViaEditor,
} from '../actions/navigation'
import {downloadTickets} from '../actions/order-success'
import {applyPlan, getPlanList, openMembershipPicker} from '../actions/paid-plans'
import {paymentMethodSelected, placeOrderButtonClicked} from '../actions/payment'
import {getOrder, placeOrder, updateOrder} from '../actions/placed-order'
import {openPolicyModal, toggleAgreePolicies} from '../actions/policies'
import {registrationButtonClicked} from '../actions/registration'
import {cancelReservation, reserveTickets} from '../actions/reservation'
import {
  changeMemberRsvp,
  changeResponse,
  editRsvpStep,
  handleNextRsvpStep,
  handleRSVP,
  resetRsvpError,
  sendRsvp,
  submitRsvpStep,
  updateRsvp,
} from '../actions/rsvp'
import {navigateToSchedulePage} from '../actions/schedule'
import {selectTicket, unselectTicket} from '../actions/selected-tickets'
import {updateSettings} from '../actions/settings'
import {collapseDescription, expandDescription} from '../actions/tickets-picker'
import {Actions} from '../types'

export const getExportedActions = (store: Store, appLoaded, monitor): Actions => {
  const dispatchActions = {
    navigateToForm,
    navigateToMainPage,
    detailsPageLoaded,
    formPageLoaded,
    thankYouPageLoaded,
    ticketedThankYouPageLoaded,
    changeMemberRsvp,
    registrationButtonClicked,
    updateStyleParams,
    setCouponCode,
    resetCouponCode,
    resetRsvpError,
    sendRsvp,
    updateRsvp,
    reserveTickets,
    selectTicket,
    unselectTicket,
    ensureLoginForMembersOnly,
    expandDescription,
    collapseDescription,
    getMembers,
    getPlanList,
    openMembershipPicker,
    promptLogin,
    navigateToTicketsPicker,
    openCheckoutUnavailable,
    getOrder,
    updateOrder,
    placeOrder,
    paymentMethodSelected,
    placeOrderButtonClicked,
    navigateToDetails,
    nextFormClicked,
    openTicketsDetailsModal,
    openTicketsDownloadModal,
    cancelReservation,
    applyCoupon,
    addToCalendarClicked,
    applyPlan,
    handleRSVP,
    checkout,
    submitCheckoutStep,
    editStep,
    openTimeExpiredModal,
    openCantCompletePaymentModal,
    setValidPaymentAdded,
    navigate,
    navigateViaEditor,
    setUseBuyerDetails,
    setTicketDetails,
    clearInvoice,
    navigateToOrder,
    navigateToChangeRsvp,
    setExpandedTicketIndex,
    handleNextStep,
    openMembersModal,
    updateSettings,
    publishComponentSettings,
    clearCheckout,
    getDiscount,
    downloadTickets,
    shareEvent,
    toggleAgreePolicies,
    openPolicyModal,
    submitRsvpStep,
    handleNextRsvpStep,
    editRsvpStep,
    changeResponse,
    setFormFactor,
    closeNativeModal,
    navigateToSchedulePage,
  }

  const actions: Actions = {
    ...bindActionCreators(dispatchActions, store.dispatch),
    appLoaded,
  }

  return monitor.bindActions(actions)
}
